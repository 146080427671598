import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Base64 } from 'js-base64';
import { useParams, Link } from 'react-router-dom';  // Add Link from 'react-router-dom'
import { formatDateTime, API_BASE_URL } from "../../App";
import "../../index.css";
import "../../TableSection.css";

function AssetSignals() {
    const { symbol, strategy } = useParams();  // Extract symbol and strategy from route params
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Construct the API endpoint with encoded filter parameters
    const apiEndpoint = useMemo(() => {
        const filters = {
            symbol: symbol || undefined,  // Include symbol if provided
            strategy: strategy
        };

        // Remove undefined keys from the filters object
        Object.keys(filters).forEach(key => filters[key] === undefined && delete filters[key]);

        return `${API_BASE_URL}/trade-strategies/`;
    }, [symbol, strategy]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(apiEndpoint);
            const data = await response.json();
            setTableData(data);  // Assume data is a list of maps (JSON objects)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [apiEndpoint]);

    useEffect(() => {
        if (!apiEndpoint) return;

        fetchData();
        const intervalId = setInterval(fetchData, 300000);  // Poll every 5 minutes
        return () => clearInterval(intervalId);  // Clean up interval on component unmount
    }, [apiEndpoint, fetchData]);

    if (loading) {
        return <div>Loading...</div>;  // Optional: Add a loading spinner
    }

    // Helper function to create table rows dynamically for each item
    const createRows = (data) => {
        return data.map((item, index) => (
            <tr key={index}>
                <td>{item.symbol}</td>
                <td>{item.strategy}</td>
                <td>{item.last_generated_signal ? formatDateTime(item.last_generated_signal.date, 'dd MMM yyyy') : null}</td>
                <td>{item.last_generated_signal ? item.last_generated_signal.price_at_purchase : null}</td>
                <td>{item.result.cagr} %</td>  {/* Display the CAGR from result */}
                <td>{item.result.investment_duration_years}</td>  {/* Display the CAGR from result */}
                <td>
                    <Link to={`/strategy/${item.symbol}/${item.strategy}`}>🔗</Link>  {/* New Link */}
                </td>
            </tr>
        ));
    };

    return (
        <div className="table-section">
            <div className="fixed-heading">
                <h2>Investment Signals</h2>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th>Strategy</th>
                            <th>Date</th>
                            <th>Price</th>
                            <th>CAGR (%)</th>
                            <th>Investment Duration (yrs)</th>
                            <th>Link</th>  {/* Add a new column for the link */}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? createRows(tableData) : <tr><td colSpan="4">No data available</td></tr>} {/* Update colspan */}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AssetSignals;